import React, {useState, useEffect, useCallback} from "react";
import Cardview from "../Assets/HubView/Cardview";
import {Card} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setId} from "../../../redux/actions";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../components/spinner";
import axios from "axios";
import config from "../../../config.json";

const AssetView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [assetDetails, setAssetDetails] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {subPlatformData} = useSelector((state) => state.assets);

  const getAssetDetails = useCallback(() => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      sub_media_platform: subPlatformData.name,
      category: subPlatformData.category,
      performance_value: subPlatformData.performance_value,
      asset_count: subPlatformData.displayedCount,
    };
    axios
      .post(
        `${config.serviceUrl}/integration_maps/get-integration-map-assets`,
        requestBody,
        configuration
      )
      .then((response) => {
        setAssetDetails(response.data);
      })
      .catch((error) => {
        // console.error("Error fetching journey maps:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }, [subPlatformData]);

  useEffect(() => {
    getAssetDetails();
  }, [getAssetDetails]);

  const handleImageClick = (image) => {
    const encodedImageId = encodeURIComponent(image.id);
    dispatch(setId(image.id));
    setTimeout(() => navigate(`/assets/details/${encodedImageId}`), 500);
  };

  return (
    <div
    className="container-fluid position-relative z-index-1"
    style={{ top: "58px", marginBottom: "100px" }}>
    {isLoading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    ) : (
      <div className="row mt-3">
        {assetDetails &&
          assetDetails.map((asset) => (
            <div className="col-auto p-0 mb-2" key={asset.id}>
              <Card className="card-modal integrationmap-card border-0 pb-0 m-1 h-100">
                <Card.Body className="rounded-bottom d-flex flex-column">
                  <Cardview
                    asset={asset}
                    handleImageClick={handleImageClick}
                  />
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    )}
  </div>
  
  );
};

export default AssetView;
