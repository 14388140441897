import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ClientLogin from "./auth/login_form";
import HomePage from "./pages/HomePage/HomePage";
import Policy from "./pages/policy";
import Spinner from "./components/spinner";
import {useSelector, useDispatch} from "react-redux";
import { setUser } from "./redux/actions";
import { getAPIConfig } from "./common/utils";
import { getUser } from "./common/utils";


const AuthWrapper = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.assets);
  // const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const redirectUrl = location.pathname + location.search;
  const dispatch = useDispatch();

  const config_home = useMemo(() =>  getAPIConfig(), []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUser();
        if (response.status === 200) {
          setAuthenticated(true);
          dispatch(setUser(response.data.user));
        }
      } catch (error) {
        handleLogout(); // Call your logout function
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line
  }, []);

  const handleLogout = useCallback(() => {
    const newUrl = window.location.origin;
    window.history.replaceState(null, null, newUrl);
    sessionStorage.clear();
    localStorage.clear();
    setAuthenticated(false);
  }, []);

  if (loading) {
    return (
      <div className="dashboard-image d-flex justify-content-center align-items-center vh-100">
        <Spinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={authenticated ? <Navigate to="/assets" /> : <Navigate to={`/client-login?redirect=${redirectUrl}`} />} />
      <Route path="/client-login" element={
        authenticated ? <Navigate to="/assets" /> : <ClientLogin setAuthenticated={setAuthenticated} redirect={new URLSearchParams(location.search).get("redirect") || "/assets"} />
      } />
      <Route path="/policy" element={<Policy />} />
      <Route path="/assets/*" element={authenticated ? <HomePage user={user} handleLogout={handleLogout} configHome={config_home} authenticated={authenticated} /> : <Navigate to={`/client-login?redirect=${redirectUrl}`} />} />
      {["/api", "/adminconsole", "/assetupload", "/integrationmap", "/integrationmap/assetview/:id", "/assets/details/:id", "/profile"].map(path => (
        <Route key={path} path={path} element={authenticated ? <HomePage user={user} handleLogout={handleLogout} configHome={config_home} authenticated={authenticated} /> : <Navigate to={`/client-login?redirect=${redirectUrl}`} />} />
      ))}
    </Routes>
  );
};

export default AuthWrapper;
